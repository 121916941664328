<template>
  <div
    class="form-input-password form-field"
    :class="{
      'has-content': hasContent || hasFocus,
      'has-error': errors.length > 0,
    }"
  >
    <label class="form-input__label" :for="data.field_key">
      {{
        data.label
          + (data.is_required ? ' *' : '')
      }}
    </label>

    <input
      :id="data.field_key"
      :name="data.field_key"
      :type="isVisiblePassword ? 'text' : 'password'"
      :minlength="data.val_min_length"
      :required="data.is_required"
      class="form-input__element"
      autocomplete="off"
      @input="
        setValue($event);
        handleEmit($event, data.field_key);
      "
      @focus="setFocus(true)"
      @focusout="setFocus(false)"
    />

    <div
      class="toggle-password"
      @keydown="isVisiblePassword = !isVisiblePassword"
      @click="isVisiblePassword = !isVisiblePassword"
    >
      <AppIcon icon="password" max-width="21px" fill="#003E30" stroke="#003E30" />
    </div>

    <FormFieldErrors :errors="errors" />
  </div>
</template>

<script setup>
const emit = defineEmits(['emitValue', 'hasErrors']);

const props = defineProps({
    data: {
        type: Object,
        default: () => {},
    },
});

const { buildValidationObject } = useFormValidation();

const {
    hasFocus,
    hasContent,
    errors,
    handleEmit,
    setValue,
    setFocus,
} = useFormField(emit, buildValidationObject(props.data));

const isVisiblePassword = ref(false);
</script>

<style lang="scss" scoped>
.form-input__element {
    @include form-input-element;
}

.form-input__label {
    @include form-input-label;
}

.form-input-password {
    @include form-input-wrapper;
}

.toggle-password {
    position: absolute;
    top: 0;
    right: 30px;
    display: flex;
    height: 60px;
    align-items: center;
    justify-content: center;
    margin: auto;
    cursor: pointer;

    @include mobile {
        right: 24px;
        height: 50px;
    }
}
</style>
